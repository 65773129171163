import React from "react";
import logo from "./logo.png";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { FaInstagram, FaTwitter, FaGithub, FaLinkedin } from "react-icons/fa";
import video from "./video.mp4";
import "./App.css";

function App() {
  const products = [
    {
      Name: "Drinkie",
      Url: "./drinkieicon.png",
      Link: "//apps.apple.com/us/app/drinkie-water-drink-tracker/id6444912802",
    },
    {
      Name: "House Pass",
      Url: "./housepassicon.png",
      Link: "//housepass.io",
    },
    {
      Name: "MotivApp",
      Url: "./motivationapp.png",
      Link: "//play.google.com/store/apps/details?id=com.motivapp.motivasyon",
    },
    {
      Name: "HerbySnacks",
      Url: "./herbysnackslogo.png",
      Link: "//herbysnacks.com",
    },
    {
      Name: "Ask Me If You Can",
      Url: "./askmeifyoucan.png",
      Link: "//play.google.com/store/apps/details?id=com.askmeifyoucan.trivia",
    },
    {
      Name: "Motivation Bot",
      Url: "./mb7logo.jpeg",
      Link: "//twitter.com/motivationbot7",
    },
  ];

  return (
    <Router>
      {/*<video autoPlay muted loop playsInline className="background-video">
        <source src={video} type="video/mp4" />
  </video>*/}
      <div className="app">
        <div className="app-container">
          <div className="col">
            <img src={logo} className="app-logo" alt="logo" />
          </div>
          <div className="col">
            <p>contact@emretelli.net</p>
            <ul className="list-container">
              <li className="link-container">
                <Link
                  to={"//instagram.com/7emretelli"}
                  className="social-link"
                  target="_blank"
                >
                  <FaInstagram className="link-icon" />
                  Instagram
                </Link>
              </li>
              <li className="link-container">
                <Link
                  to={"//github.com/7emretelli"}
                  className="social-link"
                  target="_blank"
                >
                  <FaGithub className="link-icon" />
                  Github
                </Link>
              </li>
              <li className="link-container">
                <Link
                  to={"//www.linkedin.com/in/emre-telli/"}
                  className="social-link"
                  target="_blank"
                >
                  <FaLinkedin className="link-icon" />
                  LinkedIn
                </Link>
              </li>
              <li className="link-container">
                <Link
                  to={"//twitter.com/7Emretelli"}
                  className="social-link"
                  target="_blank"
                >
                  <FaTwitter className="link-icon" />
                  Twitter
                </Link>
              </li>
            </ul>
          </div>
          <div className="col">
            <h1>Full Stack</h1>
            <h4>Web And Mobile Developer</h4>
          </div>
          <div className="products-container">
            {products.map((product, index) => {
              return (
                <Link to={product.Link} target="_blank">
                  <div className="product-container" key={index}>
                    <img className="product-image" src={product.Url} />
                    <text className="product-name">{product.Name}</text>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
